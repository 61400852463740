<template>
  <announcement-banner></announcement-banner>
<div>
    <Menu />
    <div class="">
            <div class="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <h2 class="text-3xl font-extrabold tracking-tight pl-3 text-2xl lg:text-4xl font-extrabold bg-gradient-to-b from-yellow-200 via-yellow-400 to-yellow-700 bg-clip-text text-transparent sm:text-4xl">
                <span class="block">Please Forgive Us.</span>
                
            </h2>
            <span class="block text-white">Relay Is Having Trouble Serving Picures To Our Site. Update Coming Soon. </span>
            </div>
        </div>
   <div class="relative bg-gray-500 overflow-hidden">
    <div class="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full" aria-hidden="true">
      <div class="relative h-full max-w-7xl mx-auto">
        <svg class="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
          <defs>
            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
        </svg>
        <svg class="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
          <defs>
            <pattern id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
        </svg>
      </div>
    </div>
   </div>
        
        
        
        <main class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
        <div class="text-center">
          <h1 class="text-4xl tracking-tight font-extrabold text-gray-100 sm:text-5xl md:text-6xl">
            <span class="block text-indigo-600 xl:inline">CLAIM YOUR</span>
            {{ ' ' }}
            <span class="block  xl:inline">$POO</span>
          </h1>
          <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">Every week you can claim something <br /> Sometimes you may get $POO, sometimes it might be something cooler </p>
          <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <claimable class='text-white'></claimable>
          </div>
        </div>
      </main>
    </div>
    <div class='h-auto'><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></div>
    <Footer />
</template>

<script>
import { reactive, toRefs } from 'vue'
import Menu from "./../components/MenuComponent2.vue"
import Footer from "./../components/Footer.vue"
import Claimable from "./../components/Claimable.vue"
import AnnouncementBanner from '../components/AnnouncementBanner.vue'
export default {
    components: { Menu, Footer, Claimable, AnnouncementBanner },
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    }
}
</script>

<style lang="scss" scoped>

</style>