<template>
    <menu-component-2></menu-component-2>
    <div class="pt-10 sm:pt-16 lg:overflow-hidden lg:pt-8 ">
        <div class="mx-auto max-w-7xl lg:px-8">
          <div class="lg:grid lg:grid-cols-2 lg:gap-8">
            <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
              <div class="lg:py-24">
                <router-link to="/treasury" class="inline-flex items-center rounded-full bg-black p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base">
                  <span class="rounded-full bg-gradient-to-r from-indigo-500 via-blue-500 to-teal-500 px-3 py-0.5 text-sm font-semibold leading-5 text-white">Buy With $POO</span>
                  <span class="ml-4 text-sm">Visit The Treasury</span>
                  <ChevronRightIcon class="ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                </router-link>
                <h1 class="mt-4 text-4xl font-bold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                  <span class="block ">INTRODUCING</span>
                  <span class="bg-gradient-to-r from-blue-700 via-blue-200 to-blue-700 bg-clip-text text-transparent">UNIQORDS</span>
                </h1>
                <p class="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">A Sub10k Unique Unicorn Ordinal Collection on 1Sat Ordinals</p>
              </div>
            </div>
            <div class="mt-12 -mb-16 sm:-mb-48 lg:relative lg:m-0">
              <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                <!-- Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ -->
                <img class="w-1/2 mx-auto mt-20 lg:ml-32 lg:absolute lg:inset-y-0 rounded" src="https://slavettes-layers.s3.amazonaws.com/uniqords/fcf898f9ec36c5652fcf5b7b7b03d3435f278fa20417a84572e105cd890fef1e_0.webp" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white py-24 sm:py-32">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
                <div class="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <div class="lg:pr-4">
                    <div class="relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                    <img class="absolute inset-0 h-full w-full object-cover brightness-125 saturate-0" src="https://slavettes-layers.s3.amazonaws.com/uniqords/fcf898f9ec36c5652fcf5b7b7b03d3435f278fa20417a84572e105cd890fef1e_0.webp" alt="" />
                    <div class="absolute inset-0 bg-gray-600 mix-blend-multiply" />
                    <div class="absolute left-1/2 top-1/2 -ml-16 -translate-x-1/2 -translate-y-1/2 transform-gpu blur-3xl" aria-hidden="true">
                        <div class="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-50" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
                    </div>
                    <figure class="relative isolate">
                        
                        <blockquote class="mt-6 text-xl font-semibold leading-8 text-white">
                        <p>relics of a time when the first pixels of the JPEG Wars were being painted on the vast canvas of the blockchain.</p>
                        </blockquote>
                        <figcaption class="mt-6 text-sm leading-6 text-gray-300"><strong class="font-semibold text-white">IAMPEWNICORN,</strong> Stud Club President</figcaption>
                    </figure>
                    </div>
                </div>
                <div>
                    <div class="text-base leading-7 text-gray-700 lg:max-w-xl">
                    <p class="text-base font-semibold leading-7 text-indigo-600">WE ARE ANCIENT ARTIFACT THEORISTS</p>
                    <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">✨UNVEILING THE UNIQORDS✨</h1>
                    <div class="max-w-xl text-left">
                        <p class="mt-6">Greetings, Studs! Our origin begins with and event that shook our meta-verse. The day Ordinals collided with the blockchain, shattering the bears with a resounding boom. The landscape was forever changed, and our front-line fighters, Shruggr and Stachmo, took to their star stations, shaping the explosion of bits into 1sat ordinals.</p>
                        <p class="mt-8">Just a flicker in the meta timeline—218 blocks after launch—our Doodle Corns emerged from the fiber optic forge, blazing with vibrant hues. They are no ordinary creations but 'Ancient Artifacts', <span class="font-black">relics of a time when the first pixels of the JPEG Wars were being painted on the vast canvas of the blockchain</span>.</p>
                        <p class="mt-8">Our beloved IAMPewnicorn, in its legendary status, birthed a new collection of unicorns. But these weren't just any unicorns, these were Uniqords, ready to fight on behalf of our BSV blockchain, distinguishing themselves from BTC and ETH warriors.</p>
                    </div>
                    </div>
                    <dl class="mt-10 grid grid-cols-2 gap-8 border-t border-gray-900/10 pt-10 sm:grid-cols-4">
                    <div v-for="(stat, statIdx) in stats" :key="statIdx">
                        <dt class="text-sm font-semibold leading-6 text-gray-600">{{ stat.label }}</dt>
                        <dd class="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900">{{ stat.value }}</dd>
                    </div>
                    </dl>
                    <div class="mt-10 flex space-x-12 text-left">
                        <a href="https://jpegwars.gg/" class="text-base font-semibold leading-7 text-gray-800">Update Your Profile <span aria-hidden="true">&rarr;</span></a>
                    <a href="https://discord.gg/cKaZpxzCPz" class="text-base font-semibold leading-7 text-purple-600">Join The Discord <span aria-hidden="true">&rarr;</span></a>
                    <a href="https://discord.gg/cKaZpxzCPz" class="text-base font-semibold leading-7 text-yellow-600">1SAT Ordinals<span aria-hidden="true">&rarr;</span></a>
                    <a href="https://jpegwars.gg/" class="text-base font-semibold leading-7 text-blue-800">Check out JPEG Wars <span aria-hidden="true">&rarr;</span></a>
                    </div>
                </div>
                </div>
            </div>
        </div>
      <div class="w-full mx-auto mt-10 px-6 lg:px-8">
        <div class="mx-auto max-w-5xl">
          <h2 class="text-base font-semibold leading-8 text-indigo-400">
            <p class="mt-2 text-3xl font-bold tracking-tight sm:text-4xl pt-12">CLAIM YOUR UNIQORDS AND JOIN THE JPEG WARS! </p>
          </h2>
          <p class="mt-6 text-lg leading-8 text-gray-300 text-left">
            <span class="text-xl font-bold text-white">Why is this ancient artifact discovery of significance to our club?</span> If you had the honor of being a Pewnicorn guardian on June 9th, 2023, rejoice—you're now a member of an cross-chain alliance of early adopters! You're among the chosen to bring an ancient artifact into your collection. Uniqords are some of the most 0.1% most rare 1sat ordinals with numbers ranging from 6k to 8k. 
          </p>
          <p class="mt-6 text-lg leading-8 text-gray-300 text-left">
            Around 170 members, including our recently initiated metaspace navigators, Shrugger and Satchmo, form our digital assembly, each standing to command ~2 Uniqords in the upcoming battle of the JPEG Wars.
          </p>
          
          <p class="mt-6 text-lg leading-8 text-gray-300 text-left">
            Follow the bitmap map to pewnicornSocial.club to secure your Uniqords, but remember, the clock is ticking! You have 180 galactic cycles (days) to claim your artifact of this discovery event.
          </p>
          <p class="mt-6 text-lg leading-8 text-gray-300 text-left">
            Our Uniqords stand as meta sentinels, numbered #1 to #500.
          </p>
          <p class="mt-6 text-lg leading-8 text-gray-300 text-left">
            <span class="text-white font-black">Uniqords #1-170</span> will be transported via airdrop to our most substantial Pewnicorn guardians where the biggest bag gets the lowest number.
          </p>
          <p class="mt-6 text-lg leading-8 text-gray-300 text-left">
            <span class="text-white font-black">Uniqords #171-340</span> will be available for interstellar exchange by club members for $50,000 $POO each, processed on a first-come, first-served basis in the magical land weve created on discord.
          </p>
          <p class="mt-6 text-lg leading-8 text-gray-300 text-left">
            <span class="text-white font-black">Uniqords #341-500</span> will be set adrift for acquisition on the open BSV market.
          </p>
          <p class="mt-6 text-lg leading-8 text-gray-300 text-left">
            Studs, the JPEG Wars have erupted across the cosmos, and our Uniqords are among the first to take up the banner for BSV. This is not merely a claim of an exquisite NFT artifact—it's a call to join the struggle for the dominance of Satoshis Vision.
          </p>
          <p class="mt-6 text-lg leading-8 text-gray-300 text-left">
            Join us, secure your Uniqords, enlist in the Stud Army, and let's forge a path through the metaverse in the JPEG Wars.
          </p>
          <p class="mt-6 text-lg leading-8 text-white text-left font-black">
            Turn On! Link Up! Dive In!
          </p> 
        </div>
      </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import MenuComponent2 from '../components/MenuComponent2.vue'
const stats = [
    { label: 'TOTAL', value: '500' },
    { label: 'Lowest Inscription', value: '6k' },
    { label: 'Total Airdrop', value: '170' },
    { label: 'Price in $POO', value: '$50K' },
  ]
export default {
  components: { MenuComponent2 },
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state), stats
        }
    }
}
</script>

<style lang="scss" scoped>

</style>




