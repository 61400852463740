const config = {
  apiKey: "AIzaSyAGAT1TAOqovX_dO4NPrd2DSDlwhCYmAt8",
  authDomain: "psc-website-a39d7.firebaseapp.com",
  projectId: "psc-website-a39d7",
  storageBucket: "psc-website-a39d7.appspot.com",
  messagingSenderId: "482594006304",
  appId: "1:482594006304:web:895bbe0d7edda8eda72345",
  measurementId: "G-THV9S3X34T"

}
export {
config
}