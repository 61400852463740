<template>
  <div class="relative bg-purple-600">
    <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div class="pr-16 sm:text-center sm:px-16">
        <p class="font-medium text-white">
          <span class="md:hidden"> Preparing for Airdrop </span>
          <span class="hidden md:inline"> Preparing For Airdrop. Add Your 1SAT Address! </span>
          <span class="block sm:ml-2 sm:inline-block">
            <router-link to="/profile" class="text-yellow-400 font-bold underline"> Update My Profile <span aria-hidden="true">&rarr;</span></router-link>
          </span>
        </p>
      </div>
      <div class="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
        <button type="button" class="flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white">
          <span class="sr-only">Dismiss</span>
          <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { XIcon } from '@heroicons/vue/outline'

export default {
  components: {
    XIcon,
  },
}
</script>