<template>
  <div class="relative py-16 bg-white">
    <div class="hidden absolute top-0 inset-x-0 h-1/2 bg-gray-50 lg:block" aria-hidden="true" />
    <div class="max-w-7xl mx-auto bg-indigo-600 lg:bg-transparent lg:px-8">
      <div class="lg:grid lg:grid-cols-12">
        <div class="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
          <div class="inset-x-0 h-1/2 bg-gray-50 lg:hidden" aria-hidden="true" />
          <div class="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
            <div class="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
              <img class="p-1 object-cover object-center rounded-3xl shadow-2xl" src="https://slavettes-layers.s3.amazonaws.com/pewnicorns/Poo-transparent.png" alt="" />
            </div>
          </div>
        </div>

        <div class="relative bg-gray-900 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
          <div class="hidden absolute inset-0 overflow-hidden rounded-3xl lg:block" aria-hidden="true">
            <svg class="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
              <defs>
                <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-indigo-500" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
            </svg>
            <svg class="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
              <defs>
                <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-indigo-500" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
            </svg>
          </div>
          <div class="relative text-gray-100 bg-gray-900 max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-md lg:p-0 lg:col-start-3 lg:col-span-6">
            <h2 class="text-3xl font-extrabold text-white" id="join-heading">What is $POO Coin?</h2>
            <p class="text-lg text-left">
            Like Horse Shit, But ✨
            <span class="text-yellow-400 font-extrabold"> Magical </span> ✨
            <br />
            <span class="text-yellow-400"> $POO </span> lubes up the
            Pewconomy, powering everything you can do in the Pewniverse. The
            more 'Corns you have in your stable, the more
            <span class="text-yellow-400"> $POO </span> you'll stack. <br />
            Drop <span class="text-yellow-400"> $POO </span> to vote in the
            DAO, get early access to merch drops & JPEGs, take a 'Corn from
            the stable, or just shit in random in a random n0obs wallet.
            <br />
            <span class="text-yellow-400"> $POO </span> can ALWAYS be used
            to add 'Corns to your stable.<br />
            <br />
            
            </p>
            <div> 
            <a
                class="block w-full py-3 px-5 text-center bg-indigo-700 border border-transparent rounded-md shadow-md text-base font-medium text-white hover:bg-gray-50 sm:inline-block sm:w-auto"
                target="_blank"
                href="https://swap.relayx.com/swap"
            >
               Swap For $POO
            </a>
            </div>
            <div>
            <a class="block w-full py-3 px-5 text-center bg-white border border-transparent rounded-md shadow-md text-base font-medium text-indigo-700 hover:bg-gray-50 sm:inline-block sm:w-auto" href="https://www.relayx.com/market/POO">Buy $POO Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>