<template>
  <div class="bg-gray-900 py-24 sm:py-32">
    <div class="relative isolate">
      <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
          <img class="h-96 max-w-sm mx-auto flex-none rounded-2xl shadow-xl lg:aspect-square lg:h-auto lg:max-w-xs" src="https://slavettes-layers.s3.amazonaws.com/uniqords/fcf898f9ec36c5652fcf5b7b7b03d3435f278fa20417a84572e105cd890fef1e_0.webp" alt="" />
          <div class="w-full flex-auto">
            <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Introducing: Uniqords</h2>
            <p class="mt-6 text-lg leading-8 text-gray-300">Uniqords are a Sub 10k 1sat ordinals collection, and are a JPEG Wars team from launch day. </p>
            <ul role="list" class="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-white sm:grid-cols-2">
              <li v-for="benefit in benefits" :key="benefit" class="flex gap-x-3">
                <CheckCircleIcon class="h-7 w-5 flex-none" aria-hidden="true" />
                {{ benefit }}
              </li>
            </ul>
            <div class="mt-10 flex space-x-6">
              <div class="mt-10 flex">
                    <router-link to="/uniqords" class="text-sm font-semibold leading-6 text-yellow-400">Read The Story <span aria-hidden="true">&rarr;</span></router-link>
                </div>
                <div class="mt-10 flex">
                    <router-link to="/profile" class="text-sm font-semibold leading-6 text-yellow-400">Update Your Profile <span aria-hidden="true">&rarr;</span></router-link>
                </div>
                <div class="mt-10 flex">
                    <a href="https://discord.gg/cKaZpxzCPz" target="_blank" class="text-sm font-semibold leading-6 text-purple-400"> Join The New Discord <span aria-hidden="true">&rarr;</span></a>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl" aria-hidden="true">
        <div class="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25" style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { CheckCircleIcon } from '@heroicons/vue/solid'

const benefits = [
  '1 airdropped per club member',
  'Team in JPEG Wars',
  'Each member can buy 1 with poo*',
  'Remaining sold for BSV on Market',
  'Benefits the club first',
]
</script>