<template>
    <div class="text-white">
        <Menu></Menu>
      <h1>User Profiles</h1>
      <div v-for="profile in profiles" :key="profile.id">
        <p><span class="text-xl text-blue-500"> {{ profile.relay_handle }} {{ profile.twitterHandle }}</span> - {{ profile.ordinalsAddress }}</p>
      </div>
    </div>
  </template>
  
  <script>
  import { onMounted, ref } from 'vue';
  import {userProfiles} from './../../services/firebase';
  import Menu from "./../../components/MenuComponent2.vue"

  export default {
    components: {Menu},
    name: 'UserProfile',
    setup() {
      const profiles = ref([]);
  
      const fetchProfiles = async () => {
        let {getProfiles} = userProfiles();
        console.log("Aboiut to fetch profiles");
        profiles.value = await getProfiles();
      };
  
      onMounted(fetchProfiles);
  
      return { profiles };
    },
  };
  </script>
  